.section-intro {
  margin-top: 0;
}

.section-intro-content {
  padding-top: var(--spacing-small);
}

@media (max-width: 640px) and (max-height: 825px) {
  .section-intro {
    display: flex;
    flex-direction: column;
    margin-top: 0;

    & header {
      flex-grow: 0;
    }

    & h1 {
      @media (max-height: 750px), (max-width: 425px) {
        margin-left: calc(var(--spacing-small) / 2 * -1);
        margin-right: calc(var(--spacing-small) / 2 * -1);
        margin-bottom: 15px;
        font-size: 2.2rem;
      }
    }
  }

  .section-intro-content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-evenly;
  }
}

.large-text {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.3;

  @media (max-width: 640px) {
    font-size: 1.5rem;
  }
}

@media (max-width: 640px) {
  .sign-up-button {
    display: block;
  }
}

.partner-logo-wrapper {
  text-align: center;
}

.partner-logo {
  display: inline-block;
  margin: 0 auto;
  text-align: center;

  & img {
    width: 300px;
  }
}

iframe {
  max-width: 100%;
  max-width: 100%;
}

.schedule {
  & th,
  & td {
    padding: 5px 5px;
    font-size: 1.6rem;

    @media (max-width: 740px) {
      display: block;
      font-size: 1.3rem;
    }
  }

  & th {
    padding-right: 15px;
    min-width: 140px;
    font-weight: bold;
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
    text-align: right;

    @media (max-width: 740px) {
      padding-right: 5px;
      padding-bottom: 0;
      text-align: left;
    }
  }
}

.team-section {
  --columns: 5;

  & ul {
    --gap: var(--spacing-small);
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    margin-bottom: var(--spacing-medium);
    padding: 0;
    gap: var(--gap);
    justify-content: space-around;
    list-style: none;
  }

  & li {
    flex-basis: calc((100% / var(--columns)) - var(--gap));
    text-align: center;
  }

  & span {
    display: block;
    text-align: center;
  }

  @media (max-width: 900px) {
    --columns: 3;

    & li {
      flex-basis: calc((100vw / var(--columns)) - var(--spacing-small) - (var(--gap) / var(--columns)));
    }
  }

  @media (max-width: 550px) {
    --columns: 2;

    & ul {
      gap: var(--spacing-tiny);
    }
  }
}

ul.coaches-list {
  justify-content: center;
}

.coaches-list li {
  --columns: 3;
  display: flex;
  align-items: center;

  font-size: 90%;

  @media (max-width: 900px) {
    --columns: 2;
  }

  @media (max-width: 600px) {
    --columns: 1;
  }
}
.coaches-list li span {
  text-align: left;
}

.coach-placeholder {
  --size: 100px;
  margin-right: 10px;
  min-width: var(--size);
  min-height: var(--size);
  background-image: url(../images/brandmark.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  filter: hue-rotate(340deg);

  @media (max-width: 600px) {
    --size: 40px;
    margin-right: 10px;
    background-size: 95%;
  }
}

[class^=hero-] {
  display: block;
  width: 100%;
  height: 350px;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 640px) {
    height: 200px;
    background-size: cover;
  }
}

.hero-home {
  background-image: url(../images/headers/home.jpg);
}

.map {
  display: none;
}

.map-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 900px;
  max-width: 100%;
  height: 450px;
  text-align: center;
  background: url(../images/blob.svg), #FBFBFB;
  background-position: center center, center center;
  background-size: 1000px 900px, cover;
  background-repeat: no-repeat;
  border-radius: 30px;

  & .button {
    font-size: 100%;
  }
}
